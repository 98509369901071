<template>
  <div class="notive-details">
    <van-sticky>
      <div class="query">
        <input type="text" placeholder="请输入您想搜索的内容" v-model="name">
        <button @click="selectNotice">搜索</button>
        <img src="~img/25.png">
      </div>
    </van-sticky>
    <main>
      <div class="p1">{{title}}</div>
      <div class="p2">{{time}}</div>
      <div v-html="detail" ref="details" class="details" @click="getText($event)"></div>
    </main>
    <tabbar />
  </div>
</template>


<script>
import tabbar from "../../components/Tabbar.vue";
import wxShare from "../../utils/wxShare.js";
export default {
  components: {
    tabbar,
  },
  data() {
    return {
      id: "",
      detail: "",
      name: "",
      title: "",
      time: "",
    };
  },
  created() {
    this.id = this.getQueryValue("id");
    // this.title = this.getQueryValue("name");
    // this.time = decodeURIComponent(this.getQueryValue("time"));
    this.getDetails();
  },
  mounted() {
    // console.log(this.$refs.details.target);
    document.title = "公益报道";
  },
  methods: {
    async getDetails() {
      const res = await this.axios.get(`notice/${this.id}`);
      console.log(res);
      if (res.code === 200) {
        this.detail = res.data.detail;
        this.title = res.data.name;
        this.time = res.data.created_at;
        let doc = this.detail.substring(3, 33);
        let notice = {
          name: this.title,
          title: res.data.profile,
          image: res.data.image,
          id: res.data.id,
        };
        wxShare.noticeShare(notice);
      }
    },
    getText(event) {
      console.log(event.target.innerHTML);
    },
    selectNotice() {
      this.$router.push({ path: `/notice?name=${this.name}` });
    },
  },
};
</script>


<style lang="less" scoped>
.notive-details {
  .query {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-top: 31px;
    background: #f8f8f8;
    img {
      width: 31px;
      left: 55px;
      top: 65%;
      transform: translate(0, -50%);
      position: absolute;
    }
    button {
      font-size: 27px;
      color: #fff;
      width: 89px;
      height: 72px;
      border-radius: 10px;
      background: url("../../assets/img/24.png") no-repeat;
      background-size: contain;
      margin-right: 30px;
    }
    input {
      width: 586px;
      height: 72px;
      font-size: 27px;
      background: #fff;
      border-radius: 10px;
      padding-left: 80px;
      margin-left: 30px;
    }
    ::-webkit-input-placeholder {
      /* WebKit browsers，webkit内核浏览器 */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
  }
  main {
    .p1 {
      font-size: 36px;
      margin-bottom: 18px;
    }
    .p2 {
      font-size: 28px;
      color: #646464;
      margin-bottom: 57px;
    }
    width: 100%;
    border-radius: 18px;
    background: #fff;
    margin-top: 30px;
    font-size: 28px;
    padding: 44px 30px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: auto;
    }
  }
  .details {
    /deep/p {
      margin-bottom: 18px;
    }
  }
}
</style>